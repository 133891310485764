<template>
  <McForm
    ref="mcFormInstance"
    :loading="loadingState"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :submiticon="isEditing ? 'edit' : 'plus'"
    :title="isEditing ? $t('trackers.edit') : $t('trackers.add')"
    :submittext="isEditing ? $t('general.Save') : $t('trackers.add')"
  >
    <mc-form-select
      v-if="$perms.isAdministrator()"
      :disabled="isEditing || forceTrackerType !== false"
      name="type"
      type="text"
      v-model="curData.type"
      :label="$t('trackers.type')"
      :placeholder="$t('trackerform.chooseType')"
      key="trackerform.type"
      :errorHint="errors.first('type')"
      :state="!errors.has('type')"
      :options="typeOptions"
      :icon="['fad', 'boxes']"
    />

    <mc-form-input
      :disabled="!$perms.isAdministrator() && !$perms.hasPermission(curData.imei, 'name')"
      name="name"
      type="text"
      v-model="curData.name"
      v-validate="'max:64'"
      :data-vv-as="$t('trackers.name')"
      :label="$t('trackers.name')"
      :placeholder="$t('trackerform.enterNameOptional')"
      key="trackerform.name"
      :errorHint="errors.first('name')"
      :state="!errors.has('name')"
      icon="signature"
    />

    <mc-form-input
      v-if="$perms.isAdministrator()"
      :disabled="isEditing"
      name="imei"
      type="text"
      v-model="curData.imei"
      v-validate="'required|numeric|min:15|max:15'"
      :data-vv-as="$t('trackers.imei')"
      :label="$t('trackers.imei')"
      :placeholder="$t('trackerform.enterImei')"
      key="trackerform.imei"
      :errorHint="errors.first('imei')"
      :state="!errors.has('imei')"
      icon="hashtag"
    />

    <mc-form-input
      v-if="$perms.isAdministrator()"
      name="simId"
      type="text"
      v-model="curData.simId"
      v-validate="'required|numeric|min:19|max:20'"
      :data-vv-as="$t('trackers.simId')"
      :label="$t('trackers.simId')"
      :placeholder="$t('trackerform.enterSimId')"
      key="trackerform.simId"
      :errorHint="errors.first('simId')"
      :state="!errors.has('simId')"
      icon="sim-card"
    />

    <!-- eslint-disable-next-line -->
    <mc-icon-picker
      v-if="$perms.isAdministrator() || $perms.hasPermission(curData.imei, 'colorAndIcon')"
      name="icon"
      v-model="curData.icon"
      :label="$t('trackers.icon')"
      key="trackerform.icon"
    />
    <!-- eslint-disable-next-line -->
    <mc-color-picker
      v-if="$perms.isAdministrator() || $perms.hasPermission(curData.imei, 'colorAndIcon')"
      name="color"
      v-model="curData.color"
      :label="$t('trackers.color')"
      key="trackerform.color"
    />

    <mc-form-select
      v-if="$perms.isAdministrator() && !forceNoAdmin"
      name="administratorUserId"
      v-model="curData.userId"
      :data-vv-as="$t('general.adminUser')"
      :label="$t('general.adminUser')"
      :placeholder="$t('trackerform.chooseUser')"
      key="trackerform.userId"
      :errorHint="errors.first('userId')"
      :state="!errors.has('userId')"
      :options="userList"
      :icon="['fad', 'user-cog']"
    />

    <mc-form-select
      v-if="curData.userId > 0 && $perms.isAdministrator()"
      name="subscription"
      v-model="curData.subscription"
      :data-vv-as="$t('trackers.subscription')"
      :label="$t('trackers.subscription')"
      :placeholder="$t('trackers.chooseSubscription')"
      key="trackerform.subscription"
      :errorHint="errors.first('subscription')"
      :state="!errors.has('subscription')"
      :options="subscriptionList"
      :icon="['fad', 'money-check-alt']"
    />

    <mc-form-input
      v-if="curData.userId > 0 && $perms.isAdministrator() && curData.subscription !== 'free'"
      name="discountInCents"
      type="text"
      :v-model="curData.discountInCents"
      :label="$t('trackers.discount')"
      v-currency="{ allowNegative: false, locale: LanguageStore.currentLanguage, valueAsInteger: true }"
      :placeholder="$t('trackers.enterDiscount')"
      key="trackerform.discount"
      :description="$t('trackers.discountDescription')"
      :icon="['fad', 'badge-dollar']"
    />

    <mc-form-select
      v-if="curData.userId > 0 && ($perms.isAdministrator() || $perms.isAdministratorOfTracker(curData.imei))"
      name="ownerContactId"
      v-model="curData.ownerContactId"
      :data-vv-as="$t('trackers.ownerContact')"
      :label="$t('trackers.ownerContact')"
      :placeholder="$t('trackerform.chooseOwnerContact')"
      key="trackerform.ownerContactId"
      :errorHint="errors.first('ownerContactId')"
      :state="!errors.has('ownerContactId')"
      :options="ownerContactList"
      :icon="['fad', 'user-crown']"
    />

    <mc-form-select
      v-if="curData.userId > 0 && ($perms.isAdministrator() || $perms.isAdministratorOfTracker(curData.imei))"
      name="billingContactId"
      v-model="curData.billingContactId"
      :placeholder="$t('trackerform.chooseBillingContact')"
      :data-vv-as="$t('trackers.billingContact')"
      :label="$t('trackers.billingContact')"
      key="trackerform.billingContactId"
      :errorHint="errors.first('billingContactId')"
      :state="!errors.has('billingContactId')"
      :options="billingContactList"
      :icon="['fad', 'user-chart']"
    />

    <b-form-group>
      <b-input-group>
        <b-form-checkbox class="ml-5" v-if="curData.userId > 0 && curData.billingContactId > 0 && $perms.isAdministrator()" v-model="curData.awardSmss">
          {{ $t('trackerform.awardSmss') }}
        </b-form-checkbox>
      </b-input-group>
    </b-form-group>
    <!--pre>{{ curData }}</pre-->

    <mc-form-checkboxes
      v-if="$perms.isAdministrator() && curData.subscription !== 'free'"
      name="permissions"
      v-model="curData.permissions"
      :label="$t('trackerform.choosePermissions')"
      key="trackerform.permissions"
      :errorHint="errors.first('permissions')"
      :state="!errors.has('permissions')"
      :options="permissionList"
      icon="ballot-check"
      multiple
      allow-empty
    />

    <mc-form-date-picker
      v-if="curData.userId > 0 && $perms.isAdministrator() && curData.subscription !== 'free'"
      name="dateExpires"
      v-model="curData.dateExpires"
      :label="$t('trackerform.chooseExpiry')"
      key="trackerform.dateExpires"
      :errorHint="errors.first('dateExpires')"
      :state="!errors.has('dateExpires')"
      :icon="['far', 'calendar']"
      :config="{ format: 'L', timeZone: 'GMT' }"
    />

    <div v-if="isEditing && modeData && modeData.availableModes && modeData.availableModes.length > 1">
      <hr />
      <h3>{{ $t('trackers.RecoveryMode') }}</h3>
      <div v-if="modeData.availableModes.includes('recovery')">
        <p>{{ $t('trackers.RecoverymodeDescription') }}</p>

        <div v-if="this.modeData.mode == 'default'">
          <p>{{ $t('trackers.TrackerIsInDefaultMode') }}</p>
          <div v-if="this.modeData.waitingMode == '' || this.modeData.waitingMode == 'default'">
            <McButton v-if="hasPermsForRecoveryMode" @click="setMode('recovery')" variant="outline-secondary" class="mb-2" :icon="'bell'" :text="$t('trackers.EnableRecoveryMode')" />
          </div>
          <div v-else>
            <p>{{ $t('trackers.TrackerIsAwaitingRecoveryMode') }}</p>
            <McButton v-if="hasPermsForRecoveryMode" @click="setMode('default')" variant="outline-secondary" class="mb-2" :icon="'bell-slash'" :text="$t('trackers.SwitchBackToDefaultMode')" />
          </div>
        </div>

        <div v-if="this.modeData.mode == 'recovery'">
          <p>{{ $t('trackers.TrackerIsInRecoveryMode', { date: recoveryModeEndsDateReadable }) }}</p>
          <div v-if="this.modeData.waitingMode == 'recovery'">
            <p>{{ $t('trackers.TrackerIsAwaitingRecoveryExtension') }}</p>
          </div>
          <div v-if="this.modeData.waitingMode == 'default'">
            <p>{{ $t('trackers.TrackerIsAwaitingDefaultMode') }}</p>
          </div>
          <McButton v-if="hasPermsForRecoveryMode && this.modeData.waitingMode != 'recovery'" @click="setMode('recovery')" variant="outline-secondary" class="mb-2" :icon="['far', 'clock']" :text="$t('trackers.ExtendRecoveryMode')" />
          <div v-if="this.modeData.waitingMode != 'default'">
            <McButton v-if="hasPermsForRecoveryMode" @click="setMode('default')" variant="outline-secondary" class="mb-2" :icon="'bell-slash'" :text="$t('trackers.SwitchBackToDefaultMode')" />
          </div>
          <p class="text-danger" v-if="!hasPermsForRecoveryMode">{{ $t('trackers.NoPermissionForRecovery') }}</p>
        </div>
      </div>
      <!--pre> {{ modeData }} </pre-->
    </div>

    <!--pre> {{ curData }} </pre-->
  </McForm>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { FormatError } from '../helpers/ErrorFormatting'
import subscriptionTypes from '../helpers/SubscriptionTypes'
import { isEmptyObject } from '../helpers/Misc'
import TrackerService from '../services/TrackerService'
import MiscService from '../services/MiscService'
import trackerTypes from '../helpers/TrackerTypes'
import { datetimeLocalStringToDate, gmtTime, mysqlTimeStringFromDate, dateToLocalizedHumanreadableDate, localizedDatetime } from '../helpers/Time'

const defaultData = {
  imei: '',
  simId: '',
  name: '',
  userId: 0,
  type: 30,
  subscription: 'standard',
  discountInCents: '',
  dateExpires: '',
  ownerContactId: 0,
  billingContactId: 0,
  email: '',
  permissions: ['currentPosition', 'history', 'mileage', 'name', 'colorAndIcon', 'alarmRules', 'settings'],
  icon: 'car',
  color: '#000000',
  powerProfile: 'balanced',
  awardSmss: false,
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },

      isEditing: false,

      loadingState: true,
      contactsAndUsers: [],

      modeData: {},

      modeTimer: null,
    }
  },
  props: {
    value: Object,
    forceTrackerType: {
      type: [Boolean, Number],
      default: false,
    },
    forceNoAdmin: Boolean,
  },
  watch: {
    value(val) {
      this.$refs['mcFormInstance'].forceReset()

      //If empty object passed, we are not editing an existing object
      this.isEditing = !isEmptyObject(val)

      let newVal = {}
      for (var field in defaultData) {
        newVal[field] = val.hasOwnProperty(field) ? val[field] : defaultData[field]
      }
      if (!newVal.dateExpires || newVal.dateExpires == '0000-00-00 00:00:00' || newVal.dateExpires > '2050-01-01') {
        //console.log('1', newVal.dateExpires)
        newVal.dateExpires = datetimeLocalStringToDate()
          .add(1, 'years')
          .startOf('day')
          .toDate()
      } else {
        //console.log('2', newVal.dateExpires, gmtTime(newVal.dateExpires))
        newVal.dateExpires = gmtTime(newVal.dateExpires)
      }
      this.curData = newVal
      this.curData.awardSmss = this.curData.billingContactId == 0

      if (this.forceTrackerType !== false) {
        this.curData.type = this.forceTrackerType
      }

      this.modeData = val.modeData
      this.getMode()
    },
  },
  mounted() {
    this.modeTimer = setInterval(this.getMode, 10000)
  },
  beforeDestroy() {
    clearTimeout(this.modeTimer)
  },
  created() {
    if (this.forceNoAdmin !== true) {
      MiscService.contactsAndUsers()
        .then(data => {
          this.contactsAndUsers = data
          this.loadingState = false
        })
        .catch(error => {
          this.loadingState = FormatError(error)
        })
    } else {
      this.loadingState = false
    }
  },
  computed: {
    ...mapState('LoginStore', ['status']),
    ...mapState({
      LanguageStore: state => state.LanguageStore,
    }),

    typeOptions() {
      /*if (this.forceTrackerType !== false) {
        let ret = []
        for (var i in trackerTypes.options) {
          if (trackerTypes.options[i].value == this.forceTrackerType) {
            ret.push(trackerTypes.options[i])
          }
        }
        return ret
      }*/
      return trackerTypes.options
    },
    hasPermsForRecoveryMode() {
      return this.$perms.isAdministrator() || this.$perms.hasPermission(this.curData.imei, 'settings')
    },
    recoveryModeEndsDateReadable() {
      return this.modeData && this.modeData.recoveryModeEnds ? dateToLocalizedHumanreadableDate(localizedDatetime(this.modeData.recoveryModeEnds)) : ''
    },
    subscriptionList: function() {
      return subscriptionTypes.options
    },
    permissionList: function() {
      return [
        { value: 'currentPosition', label: this.$t('permissions.currentPosition') }, //
        { value: 'history', label: this.$t('permissions.history') },
        { value: 'mileage', label: this.$t('permissions.mileage') },
        { value: 'name', label: this.$t('permissions.name') },
        { value: 'colorAndIcon', label: this.$t('permissions.colorAndIcon') },
        { value: 'alarmRules', label: this.$t('permissions.alarmRules') },
        { value: 'settings', label: this.$t('permissions.settings') },
      ]
    },
    userList: function() {
      var theList = [{ value: 0, label: this.$t('trackerform.dontAssignUser') }]
      for (var userId in this.contactsAndUsers) {
        theList.push({ value: userId, label: this.contactsAndUsers[userId].label })
      }
      return theList
    },
    ownerContactList: function() {
      //var theList = [{ value: 0, label: this.$t('trackerform.dontAssignOwnerContact') }]
      var theList = []
      if (typeof this.contactsAndUsers[this.curData.userId] === 'object') {
        for (var contactId in this.contactsAndUsers[this.curData.userId].contacts) {
          theList.push({ value: contactId, label: this.contactsAndUsers[this.curData.userId].contacts[contactId] })
        }
      }
      return theList
    },
    billingContactList: function() {
      //var theList = [{ value: 0, label: this.$t('trackerform.dontAssignBillingContact') }]
      var theList = []
      if (typeof this.contactsAndUsers[this.curData.userId] === 'object') {
        for (var contactId in this.contactsAndUsers[this.curData.userId].contacts) {
          theList.push({ value: contactId, label: this.contactsAndUsers[this.curData.userId].contacts[contactId] })
        }
      }
      return theList
    },
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    handleReset() {
      this.curData = { ...defaultData }
    },

    setMode(newMode) {
      return TrackerService.setMode(this.curData.imei, newMode).then(
        data => {
          this.modeData = data.mode
          return Promise.resolve(data)
        },
        error => {
          console.error('Error setting tracker mode', error)
          return Promise.reject(error)
        },
      )
    },

    getMode() {
      if (!this.curData.imei || !this.isEditing) {
        return
      }
      return TrackerService.getMode(this.curData.imei).then(
        data => {
          this.modeData = data.mode
          return Promise.resolve(data)
        },
        error => {
          console.error('Error getting tracker mode', error)
          return Promise.reject(error)
        },
      )
    },

    handleSubmit(e) {
      return TrackerService.createOrUpdate({
        ...this.curData,
        dateExpires: this.curData.userId > 0 ? mysqlTimeStringFromDate(this.curData.dateExpires) : '0000-00-00 00:00:00',
      }).then(
        data => {
          this.toastSuccess(this.$t('trackerform.success'))
          this.$emit('trackerSaved', this.curData.imei)
          return Promise.resolve(data)
        },
        error => {
          console.error('Error saving tracker', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
